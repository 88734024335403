<template>
  <b-container>
    <b-row style="
        display: flex;
        flex-wrap: wrap;
        margin-right: -9px;
        margin-left: -12px;"
    >
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="text-center">
              <h5 class="text-white"><i class="ri-edit-line"></i> {{ $t('elearning_accommodation.accommodation_management') }}
                <slot v-if="$route.query.id">
                    {{ $t('globalTrans.update') }}
                </slot>
                <slot v-else>
                    {{ $t('globalTrans.entry') }}
                </slot>
              </h5>
            </div>
          </template>
          <template v-slot:headerAction>
            <!-- <router-link to="accommodation-bina" :class="'btn btn-success text-light'">{{ $t('elearning_accommodation.accommodation_management') }} {{ $t('globalTrans.list') }}</router-link> -->
            <router-link to="accommodation" :class="'btn btn-success text-light'">{{ $t('elearning_accommodation.accommodation_management') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-overlay :show="isLoading">
              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                  <b-row>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fiscal_year_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              v-model="formData.fiscal_year_id"
                              :options="fiscalYearList"
                              id="fiscal_year_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                      <ValidationProvider name="Organization" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="org_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('org_pro.organization')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model="formData.org_id"
                              :options="orgList"
                              id="org_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Office Type" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="office_type_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('org_pro.office_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model="formData.office_type_id"
                              :options="officeTypeList"
                              id="office_type_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Office" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="office_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('org_pro.office_namel')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="formData.office_id"
                            :options="officeList"
                            id="office_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Check In" vid="check_in" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="check_in"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_accommodation.check_in')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              class="fromDate"
                              v-model="formData.check_in"
                              :placeholder="$t('globalTrans.select_date')"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Check Out" vid="check_out" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="check_out"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_accommodation.check_out')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              class="fromDate"
                              v-model="formData.check_out"
                              :placeholder="$t('globalTrans.select_date')"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Guest House" vid="guest_house_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="guest_house_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('elearning_config.guest_house_name') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="formData.guest_house_id"
                            :options="guestHouseList"
                            id="guest_house_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            @change="getGeustHouse()"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Room User Type" vid="room_user_type_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="room_user_type_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('elearning_accommodation.room_user_type') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="formData.room_user_type_id"
                            :options="roomUserTypeList"
                            id="room_user_type_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            @change="getGeustHouse()"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row v-for="(detail, index) in formData.accommodation_room_details" :key="index" class="mt-3">
                      <b-col lg="2" md="2" sm="6" xs="6">
                        <ValidationProvider :vid="'room_type_id' + index"  name="Room type" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="room_type_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('elearning_config.room_type')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              v-model="detail.room_type_id"
                              @change="getFloorList(detail)"
                              :options="detail.roomTypeList"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="2" md="2" sm="6" xs="6">
                        <ValidationProvider name="Floor number" :vid="'floor_no' + index" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="floor_no"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.floor_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              v-model="detail.floor_no"
                            :options="detail.floorList"
                              @change="getRoomList(detail)"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="2" md="2" sm="6" xs="6">
                        <ValidationProvider name="Room number" :vid="'room_no' + index"   rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="room_no"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('elearning_config.room_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="detail.room_no"
                              :options="detail.roomList"
                              id="room_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="2" md="2" sm="6" xs="6">
                        <ValidationProvider name="Room Rent" :vid="'room_rent' + index" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="room_rent"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('elearning_config.room_rent')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              readonly
                              id="room_rent"
                              v-model="detail.room_rent"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <!-- <b-col lg="2" md="2" sm="6" xs="6">
                        <ValidationProvider>
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="vat_tax"
                            slot-scope="{ valid, errors }"
                          >
                            <b-form-input
                              hidden
                              id="vat_tax"
                              type="number"
                              v-model="detail.vat_tax"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col> -->
                      <b-col lg="2" md="2" sm="6" xs="6" v-if="$store.state.Auth.authUser.org_id == 9 || formData.org_id == 9">
                        <ValidationProvider>
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="person"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_accommodation.person')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="person"
                              type="number"
                              @input="getTotalRent(detail)"
                              v-model="detail.person"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="2" md="2" sm="6" xs="6" v-if="$store.state.Auth.authUser.org_id == 9 || formData.org_id == 9">
                        <ValidationProvider>
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="total_rent_person"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_accommodation.total')}}
                            </template>
                            <b-form-input
                              id="total_rent_person"
                              type="number"
                              v-model="detail.total_rent_person"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col xl="2" lg="2" sm="4" class="mt-5" v-if="formData.org_id !== 9">
                        <b-button v-show="index == formData.accommodation_room_details.length-1" variant=" iq-bg-success" size="sm" @click="addItem()"><i class="ri-add-line m-0"></i>Add</b-button>
                        <b-button v-show="index || ( !index && formData.accommodation_room_details.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                      </b-col>
                      <b-col xl="12" lg="12" sm="12" class="mt-1 text-right" v-if="formData.org_id === 9">
                        <b-button v-show="index == formData.accommodation_room_details.length-1" variant=" iq-bg-success" size="sm" @click="addItem()"><i class="ri-add-line m-0"></i>Add</b-button>
                        <b-button v-show="index || ( !index && formData.accommodation_room_details.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                      </b-col>
                    </b-row>
                  <b-row>
                    <b-col lg="12" sm="12" v-if="formData.org_id !== 9">
                    <ValidationProvider name="Food Package" vid="food_package_id" rules="">
                      <b-form-group
                        class="row"
                        label-cols-sm="2"
                        label-for="food_package_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{$t('elearning_accommodation.food_package')}}
                        </template>
                        <b-form-checkbox-group
                            v-model="formData.food_package_id"
                            :options="foodPackageList"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-checkbox-group>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    </b-col>
                   </b-row>
                   <b-row>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Guest Name" vid='guest_name' rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="guest_name"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('elearning_accommodation.guest_name_en')}}  <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="guest_name"
                              v-model="formData.guest_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Guest Name (bn)" vid='guest_name_bn' rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="guest_name_bn"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('elearning_accommodation.guest_name_bn')}}  <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="guest_name_bn"
                              v-model="formData.guest_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <!-- <b-col lg="6" sm="12">
                      <ValidationProvider name="Working Organization" vid='working_org' rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="working_org"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('elearning_accommodation.working_org_en')}}
                          </template>
                          <b-form-input
                              id="working_org"
                              v-model="formData.working_org"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Working Organization (bn)" vid='working_org_bn' rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="working_org_bn"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('elearning_accommodation.working_org_bn')}}
                          </template>
                          <b-form-input
                              id="working_org_bn"
                              v-model="formData.working_org_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col> -->
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Mobile" vid='mobile' rules="required|min:11|max:11">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="mobile"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('globalTrans.mobile')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              type="number"
                              id="mobile"
                              v-model="formData.mobile"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Emergency Contact" vid='emergency_contact' rules="required|min:11|max:11">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="emergency_contact"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('elearning_iabm.emergency_contact')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                type="number"
                                id="emergency_contact"
                                v-model="formData.emergency_contact"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Guest Organization" vid='guest_org_id' rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="guest_org_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('elearning_accommodation.working_org')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="formData.guest_org_id"
                            :options="orgGuestList"
                            id="guest_org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="formData.guest_org_id == 999">
                      <ValidationProvider name="Guest Organization Name" vid='guest_org_name' rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="guest_org_name"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('elearning_accommodation.guest_org_name')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="guest_org_name"
                              v-model="formData.guest_org_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Email" vid='email' rules="required|email">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="email"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('globalTrans.email')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="email"
                              v-model="formData.email"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="NID" vid='nid' rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="nid"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('externalUserIrrigation.nid')}}
                          </template>
                          <b-form-input
                              type="number"
                              id="nid"
                              v-model="formData.nid"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Passport" vid='passport' rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="passport"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('elearning_accommodation.passsport')}}
                          </template>
                          <b-form-input
                              id="passport"
                              v-model="formData.passport"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Address (En)" vid="address_en" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="address_en"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                              {{$t('elearning_accommodation.address_en')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="address_en"
                              v-model="formData.address"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Address (Bn)" vid="address_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="address_bn"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('elearning_accommodation.address_bn')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="address_bn"
                                v-model="formData.address_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="remarks"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('elearning_config.remarks_en')}}
                                </template>
                                <b-form-textarea
                                    rows="2"
                                    id="remarks"
                                    v-model="formData.remarks"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="remarks_bn"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('elearning_config.remarks_bn')}}
                                </template>
                                <b-form-textarea
                                    rows="2"
                                    id="remarks_bn"
                                    v-model="formData.remarks_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row class="mt-3">
                    <b-col class="text-right" lg="12" sm="12">
                      <b-button type="submit" variant="primary" class="mr-2" :disabled="isLoading">
                        <span v-if="isLoading"><b-spinner small></b-spinner> {{ $t('globalTrans.loading') }}</span>
                        <span v-else>{{ saveBtnName }}</span>
                      </b-button>
                        &nbsp;
                        <router-link to="accommodation" class="mr-1 btn btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
                    </b-col>
                  </b-row>
                </b-form>
              </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
      <!-- <pre>{{orgList}}</pre> -->
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { accommodationManagementStore, accommodationManagementUpdate, accommodationManagementShow } from '../../api/routes'
import { Common } from '@/mixins/helper-functions'
import flatpickr from 'flatpickr'
import { SpinnerPlugin } from 'bootstrap-vue'
import Vue from 'vue'
Vue.use(SpinnerPlugin)

export default {
  mixin: [Common],
  created () {
    this.formData = Object.assign({}, this.formData, {
      org_id: this.$store.state.dataFilters.orgId,
      fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
    })
    if (this.id) {
      this.getFormData()
    }
  },
  data () {
    return {
      id: this.$route.query.id,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        fiscal_year_id: 0,
        org_id: 0,
        guest_org_id: 0,
        guest_org_name: '',
        office_type_id: 0,
        office_id: 0,
        guest_house_id: 0,
        emergency_contact: '',
        room_user_type_id: 0,
        room_type_id: 0,
        food_package_id: [],
        accommodation_room_details: [
          {
            room_type_id: 0,
            roomTypeList: [],
            roomList: [],
            floorList: [],
            floor_no: 0,
            room_no: 0,
            room_rent: 0,
            vat_tax: 0,
            person: 0,
            total_rent_person: 0
          }
        ],
        check_in: '',
        check_out: '',
        guest_name: '',
        guest_name_bn: '',
        nid: '',
        mobile: '',
        email: '',
        passport: '',
        address: '',
        address_bn: '',
        remarks: '',
        remarks_bn: '',
        status: 2
      },
      saved: false,
      officeTypeList: [],
      officeList: [],
      guestHouseList: [],
      roomTypeList: [],
      roomList: [],
      floorList: [],
      loading: false,
      isDisabled: false,
      isLoading: false
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  computed: {
    orgList: function () {
        if (this.$store.state.Auth.authUser.org_id === 12) {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0 && item.value === 12)
        } else {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        }
    },
    orgGuestList: function () {
        if (this.$store.state.Auth.authUser.org_id === 12) {
            const orgData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0 && item.value === 12)
            orgData.push(
                {
                    value: 999,
                    text: 'Other',
                    text_en: 'Other',
                    text_bn: 'অন্যান্য'
                }
            )
            return orgData
        } else {
            const orgData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
            orgData.push(
                {
                    value: 999,
                    text: 'Other',
                    text_en: 'Other',
                    text_bn: 'অন্যান্য'
                }
            )
            return orgData
        }
    },
    foodPackageList: function () {
      return this.$store.state.TrainingElearning.commonObj.foodPackageList.filter(item => item.status === 1)
    },
    roomUserTypeList: function () {
      if (this.formData.org_id !== 9) {
        return this.$store.state.TrainingElearning.commonObj.roomUserTypeList
      } else if (this.formData.org_id === 9 && this.formData.office_type_id !== 118) {
        return this.$store.state.TrainingElearning.commonObj.userRentTypeList
      } else {
        return this.$store.state.TrainingElearning.commonObj.subCenterUserRentTypeList
      }
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
    'formData.org_id': function (newVal, oldVal) {
        this.officeTypeList = this.getOfficeTypeList(newVal)
    },
    'formData.office_type_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.officeList = this.getOfficeList(newVal)
        }
    },
    'formData.office_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.guestHouseList = this.getGuestHouseList(newVal)
        }
    }
  },
  methods: {
    getGeustHouse () {
      this.roomTypeList = this.getRoomTypeList(this.formData.guest_house_id)
      this.formData.accommodation_room_details = [
        {
          room_type_id: 0,
          roomTypeList: this.roomTypeList,
          floorList: [],
          roomList: [],
          floor_no: 0,
          room_no: 0,
          room_rent: 0,
          vat_tax: 0
        }
      ]
    },
    addItem () {
      this.roomTypeList = this.getRoomTypeList(this.formData.guest_house_id)
      const obj = {
        room_type_id: 0,
        roomTypeList: this.roomTypeList,
        floorList: [],
        roomList: [],
        floor_no: 0,
        room_no: 0,
        room_rent: 0,
        vat_tax: 0,
        person: 0,
        total_rent_person: 0
      }
      const key1 = parseInt(this.formData.accommodation_room_details.length - 1)
      const item = this.formData.accommodation_room_details[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
          if (item.room_type_id === '' || item.room_type_id === 0 || item.floor_no === '' || item.floor_no === 0 || item.room_no === '' || item.room_no === 0 || item.room_rent === '' || item.room_rent === 0) {
              isEmpty = false
          }
      })
      if (isEmpty === true) {
        this.formData.accommodation_room_details.push(obj)
      }
    },
    remove (key) {
      this.formData.accommodation_room_details.splice(key, 1)
    },
    async getFormData () {
      this.isLoading = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, accommodationManagementShow + '/' + this.id)
      this.formData = JSON.parse(JSON.stringify(result.data))
      var str = this.formData.food_package_id
      var temp = []
      temp = str.split(',')
      // const newData = []
      // temp.map(item => {
      // })
      this.formData.food_package_id = temp
      this.formData.accommodation_room_details = this.formData.accommodation_room_details.map(item => {
        const floorList = this.$store.state.TrainingElearning.commonObj.floorList.filter(floorList => floorList.room_type_id === item.room_type_id)
        const roomList = this.$store.state.TrainingElearning.commonObj.roomList.filter(floor => floor.floor_no === String(item.floor_no))
        const roomTypeList = this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1 && item.guest_house_id === this.formData.guest_house_id)
        const detailData = {
          roomTypeList: roomTypeList,
          floorList: floorList,
          roomList: roomList
        }
        return Object.assign({}, item, detailData)
      })
      this.isLoading = false
    },
    async register () {
      this.isLoading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: true, listReload: false }
      if (!this.id) {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${accommodationManagementStore}`, this.formData)
      } else {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${accommodationManagementUpdate}/${this.id}`, this.formData)
      }
      loadingState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadingState)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: 'Data saved successfully',
          color: '#D6E09B'
        })
        this.$router.push('/training-e-learning-service/accommodation-management/accommodation-bina')
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
        } else {
          this.$toast.error({
            title: 'Error',
            message: result.message
          })
        }
      }
      this.isLoading = false
    },
    getOfficeTypeList (orgId = null) {
        const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
        if (orgId) {
            return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        }
        return officeTypeList
    },
    getOfficeList (officeTypeId = null) {
        const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
        if (officeTypeId) {
            return officeList.filter(office => office.office_type_id === officeTypeId)
        }
        return officeList
    },
    getGuestHouseList (officeTypeId = null) {
        const houseList = this.$store.state.TrainingElearning.commonObj.guestHouseList.filter(item => item.status === 1)
        if (officeTypeId) {
            return houseList.filter(office => office.office_id === officeTypeId)
        }
        return houseList
    },
    getRoomTypeList (houseId = null) {
        const houseList = this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
        if (houseId) {
            return houseList.filter(house => house.guest_house_id === houseId)
        }
        return houseList
    },
    getRoomList (detail) {
      const roomList = this.$store.state.TrainingElearning.commonObj.roomList
      if (detail.floor_no) {
        detail.roomList = roomList.filter(floor => floor.floor_no === String(detail.floor_no) && floor.room_type_id === detail.room_type_id)
      }
      return detail
    },
    getFloorList (detail) {
      detail.floorList = this.$store.state.TrainingElearning.commonObj.floorList.filter(floorList => floorList.room_type_id === detail.room_type_id)
      const rent = this.$store.state.TrainingElearning.commonObj.roomRentList.find(roomRentList => roomRentList.room_type_id === parseInt(detail.room_type_id) && roomRentList.guest_house_id === parseInt(this.formData.guest_house_id))
      // const date1 = new Date(this.formData.check_in);
      // const date2 = new Date(this.formData.check_out);
      // const diffDays = date2.getDate() - date1.getDate();
      if (this.formData.room_user_type_id === 1) {
          detail.room_rent = rent !== undefined ? (rent.own_org_price + ((rent.own_org_price * rent.vat_tax) / 100)) : 0
          detail.vat_tax = rent !== undefined ? rent.vat_tax : 0
          return detail
      } else if (this.formData.room_user_type_id === 2) {
          detail.room_rent = rent !== undefined ? (rent.govt_price + ((rent.govt_price * rent.vat_tax) / 100)) : 0
          detail.vat_tax = rent !== undefined ? rent.vat_tax : 0
          return detail
      } else if (this.formData.room_user_type_id === 3) {
          detail.room_rent = rent !== undefined ? (rent.private_price + ((rent.private_price * rent.vat_tax) / 100)) : 0
          detail.vat_tax = rent !== undefined ? rent.vat_tax : 0
          return detail
      } else if (this.formData.room_user_type_id === 4) {
          detail.room_rent = rent !== undefined ? (rent.domestic_foreign_consultants_price + ((rent.domestic_foreign_consultants_price * rent.vat_tax) / 100)) : 0
          detail.vat_tax = rent !== undefined ? rent.vat_tax : 0
          return detail
      } else if (this.formData.room_user_type_id === 5) {
          detail.room_rent = rent !== undefined ? (rent.nars_grade10_price + ((rent.nars_grade10_price * rent.vat_tax) / 100)) : 0
          detail.vat_tax = rent !== undefined ? rent.vat_tax : 0
          return detail
      } else if (this.formData.room_user_type_id === 6) {
          detail.room_rent = rent !== undefined ? (rent.non_govt_price + ((rent.non_govt_price * rent.vat_tax) / 100)) : 0
          detail.vat_tax = rent !== undefined ? rent.vat_tax : 0
          return detail
      } else if (this.formData.room_user_type_id === 7) {
          detail.room_rent = rent !== undefined ? (rent.farmer_price + ((rent.farmer_price * rent.vat_tax) / 100)) : 0
          detail.vat_tax = rent !== undefined ? rent.vat_tax : 0
          return detail
      } else {
          detail.room_rent = ''
          detail.vat_tax = ''
          return detail
      }
    },
    getTotalRent (detail) {
          const total = detail.room_rent * detail.person
          detail.total_rent_person = total
          return detail
    }
  }
}
</script>
